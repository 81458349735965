/**
 * Loading required JS scripts and plugins such as jQuery and Bootstrap
 */

window._ = require('lodash');
window.Popper = require('@popperjs/core');
window.$ = window.jQuery = require('jquery');
require('bootstrap');
require('es6-promise').polyfill();

/**
 * Loading theme JS plugins
 */
window.moment = require('moment');
require("daterangepicker");

require('select2');

// Require inputmask using jquery
window.Inputmask = require("inputmask/dist/jquery.inputmask");

require('@fancyapps/fancybox');
require('jquery-validation');

require("./theme-shared");
require("./zipcode-market-match");
require("./events");
