jQuery(document).ready(function ($) {


    /** =============================================================== **/
    /** Accessibility Improvements **/
    /** =============================================================== **/

    // Determine accessibility focus based on mouse/keyboard use
    document.body.addEventListener('mousedown', function () {
        document.body.classList.add('using-mouse');
    });
    document.body.addEventListener('keydown', function () {
        document.body.classList.remove('using-mouse');
    });


    /** =============================================================== **/
    /** Init Input masks **/
    /** =============================================================== **/

    $(".phone-mask").inputmask({
        "mask": "(999) 999-9999"
    });

    $(".date-mask").inputmask({
        alias: "mm/dd/yyyy",
        clearIncomplete: true,
        yearrange: {
            minyear: 1900,
            maxyear: 2000
        }
    });

    $(".zip-mask").inputmask({
        "mask": "99999",
        "placeholder": "",
    });


    /** =============================================================== **/
    /** Email fields (option) text show/hide on click **/
    /** =============================================================== **/

    $(".email input").focus(function () {
        $('.email span').addClass('hide-it');
        //return false;
    });


    $('.email input').blur(function () {
        if (!$(this).val()) {
            $('.email span').removeClass('hide-it');
        }
    });


    /** =============================================================== **/
    /** Handle sticky menu on scroll **/
    /** =============================================================== **/

    $(window).on("scroll", function () {
        $("header").toggleClass("pin", $(window).scrollTop() >= 40);
    });
    $(window).trigger("scroll");

    /** =============================================================== **/
    /** Scroll to id **/
    /** =============================================================== **/

    $(".scrollto").click(function (event) {
        event.preventDefault();

        var defaultAnchorOffset = 70;

        var anchor = $(this).attr('target');

        var anchorOffset = $('#' + anchor).attr('data-scroll-offset');
        if (!anchorOffset)
            anchorOffset = defaultAnchorOffset;

        $('html,body').animate({
            scrollTop: $('#' + anchor).offset().top - anchorOffset
        }, 200);
    });

    /** =============================================================== **/
    /** Menu toogler on mobile hamburger class change **/
    /** =============================================================== **/

    $(".hamburger").on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass("is-active");
    });


    /** =============================================================== **/
    /** Slick js **/
    /** =============================================================== **/

    if ($(".our-people-slider").length) {
        $('.our-people-slider').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            }, {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }, {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    }
    ;

    /** =============================================================== **/
    /** Sales Presentation Show More/Less Logic **/
    /** =============================================================== **/

    $(".viewmore").on('click', function (event) {
        event.preventDefault();
        let $rowsHolder = $(".showMoreLessHolder");
        let $actionBtn = $(this);
        let currentStatus = $rowsHolder.css('display');
        let viewMoreText = $(this).data("vm-text");
        let viewLessText = $(this).data("vl-text");
        if (currentStatus === "none") {
            $rowsHolder.stop().slideDown(600);
            $actionBtn.text(viewLessText).toggleClass('minus', true);
        } else {
            $rowsHolder.stop().slideUp(600);
            $actionBtn.text(viewMoreText).toggleClass('minus', false);
        }
    });

    /** =============================================================== **/
    /** Init datepicker for request a visit page form **/
    /** =============================================================== **/
    const datePicker = $('.datepicker-input');
    if (datePicker.length) {
        // Init datepicker
        const datepickerMinDate = moment();
        datePicker.daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            minDate: datepickerMinDate,
            autoUpdateInput: false,
            autoApply: true,
            isInvalidDate: function (date) {
                // Disable weekend selection (Sunday = 0, Saturday = 6)
                return (date.day() === 0 || date.day() === 6);
            },
            locale: {
                firstDay: 1
            },
        }).on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('MM/DD/YYYY'));
        });
    }

    const timePicker = $('.timepicker-input');
    if (timePicker.length) {
        timePicker.daterangepicker({
            singleDatePicker: true,
            timePicker: true,
            timePickerIncrement: 5,
            locale: {
                format: 'hh:mm A'
            }
        }).on('show.daterangepicker', function (ev, picker) {
            picker.container.find(".calendar-table").hide();
        });
    }

    const dropdown = $('.custom-select');
    if (dropdown.length) {
        dropdown.select2();
    }

});