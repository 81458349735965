jQuery(document).ready(function ($) {

    const marketModal = $('#marketModal');
    if (marketModal.length) {
        marketModal.modal('show');
    }

    // Handle showing error HTML
    function showMarketError(errorClass) {
        $(".zip-code-errors .text-error").toggleClass('d-none', true);
        if (errorClass !== "") {
            $(".zip-code-errors .text-error." + errorClass).toggleClass('d-none', false);
        }
    }

    // Handle redirecting or trigger error
    function marketRedirectOrShowError(marketSlug) {
        const countyModal = $("#countyModal");
        // Check if the market is covered and redirect
        if (marketSlug !== "") {
            if (marketSlug === "outofnetwork") {
                // Not covered, show error
                showMarketError("out-of-network");
            } else {
                // Redirect to correct market
                // window.location.href = WPJS.homeURL + "/" + marketSlug;
            }
        }
        if (countyModal.find('.close').hasClass('open-zip-modal')) {
            $('.open-zip-modal').click();
        } else {
            // Hide the modal if open
            countyModal.modal("hide");
        }
    }

    // Get Market Info using ZIP code
    function getMarketFormAjax($form, zipCodeValue, pageSlugValue, provider, county) {
        $(".custom-ajax-loader").hide();
        $.ajax({
            url: WPJS.ajaxURL,
            dataType: 'json',
            type: 'POST',
            data: {
                action: "market_url",
                zipcode: zipCodeValue,
                pageSlug: pageSlugValue,
                provider: provider,
                county: county,
                ajaxNonce: WPJS.ajaxNonce
            },
            beforeSend: function () {
                // Hide any errors
                showMarketError("");
                $(".custom-ajax-loader").show();
            },
            success: function (response) {
                const countyModal = $("#countyModal");
                const providersModal = $("#providersModal");
                providersModal.modal("hide");
                countyModal.find(".getMarketByCountyForm").removeAttr('data-providers');
                $(".custom-ajax-loader").hide();
                if (response.status === "ok" && response.marketLinks.length > 0) {
                    if (marketModal.length) {
                        marketModal.modal('hide');
                    }
                    const providers = Object.keys(response.providers);
                    if (response.marketLinks.length > 1 && response.hasCounties) {
                        // Multiple counties detected, set the modal options and open it
                        $("#countyModal #market-county > option").remove();
                        const counties = [];
                        $.each(response.marketLinks, function (index, singleMarketInfo) {
                            if (!counties.includes(singleMarketInfo.countyName)) {
                                counties.push(singleMarketInfo.countyName);
                                const $countyOption = $('<option value="' + singleMarketInfo.marketSlug + '">' + singleMarketInfo.countyName + '</option>');
                                $countyOption.appendTo("#countyModal #market-county");
                            }
                        });
                        if (counties.length > 1) {
                            countyModal.modal("show");
                            countyModal.find(".getMarketByCountyForm").attr('data-providers', providers.length);
                            setProviders(providers, providersModal, false);
                        } else {
                            setProviders(providers, providersModal, true);
                        }
                    } else {
                        marketRedirectOrShowError(response.marketLinks[0].marketSlug);
                    }
                } else if (response.status === "no-match") {
                    showMarketError("zip-not-found");
                } else {
                    console.log("Match API error, please check again.");
                }
            }
        });
    }

    function setProviders(providers, providersModal, showModal) {
        if (providers.length > 1) {
            $("#providersModal #market-provider > option").remove();
            $.each(providers, function (index, singleProvider) {
                const $providerOption = $('<option value="' + singleProvider + '">' + singleProvider + '</option>');
                $providerOption.appendTo("#providersModal #market-provider");
            });
            if (showModal) {
                providersModal.modal("show");
            } else {
                providersModal.modal("hide");
            }
        }
    }

    // Handle the ZIP code form submit
    let $getMarketForm = $(".getMarketForm");
    if ($getMarketForm.length) {
        $getMarketForm.on('submit', function (event) {
            event.preventDefault();
            const zipPattern = /^\d{5}$/;
            const zipCodeValue = $getMarketForm.find(":input[name='network-zip-code']").val();
            const pageSlugValue = $getMarketForm.find(":input[name='page-slug']").val();
            // Hide any previous errors
            showMarketError("");
            let isValidZip = zipCodeValue && zipPattern.test(zipCodeValue);
            if (isValidZip) {
                // Update the visitor location based on zip code
                getMarketFormAjax($getMarketForm, zipCodeValue, pageSlugValue, 'no-provider', 'no-county');
            } else {
                if (!isValidZip) {
                    // Show invalid zip code format error
                    showMarketError("wrong-zip-format");
                }
            }
        });
    }

    // Handle the county select form submit
    const $getMarketByCountyForm = $(".getMarketByCountyForm");
    if ($getMarketByCountyForm.length) {
        $getMarketByCountyForm.on('submit', function (event) {
            event.preventDefault();
            const marketSlug = $getMarketByCountyForm.find(":input[name='market-county']").val();
            const pageSlug = $getMarketByCountyForm.find(":input[name='page-slug']").val();
            if (marketSlug !== "") {
                if (marketSlug === 'outofnetwork') {
                    marketRedirectOrShowError(marketSlug);
                } else {
                    const providers = parseInt($getMarketByCountyForm.attr('data-providers'));
                    if (providers > 1) {
                        const countyModal = $("#countyModal");
                        const providersModal = $("#providersModal");
                        countyModal.modal("hide");
                        providersModal.modal("show");
                        providersModal.attr('data-county', $getMarketByCountyForm.find('#market-county option:selected').text());
                    } else {
                        $(".custom-ajax-loader").show();
                        $.ajax({
                            url: WPJS.ajaxURL,
                            dataType: 'json',
                            type: 'POST',
                            data: {
                                action: "market_county",
                                slug: marketSlug,
                                ajaxNonce: WPJS.ajaxNonce
                            },
                            success: function () {
                                $(".custom-ajax-loader").hide();
                                if (pageSlug === 'landing-page') {
                                    marketRedirectOrShowError(marketSlug);
                                } else {
                                    marketRedirectOrShowError(pageSlug);
                                }
                            },
                            done: function () {
                                $(".custom-ajax-loader").hide();
                            }
                        });
                    }
                }
            }
        });
    }

    const $getMarketByZipForm = $(".getMarketByZipForm");
    if ($getMarketByZipForm.length) {
        $getMarketByZipForm.on('submit', function (event) {
            event.preventDefault();
            const zipPattern = /^\d{5}$/;
            const zipCodeValue = $getMarketByZipForm.find(":input[name='network-zip-code']").val();
            const pageSlugValue = $getMarketByZipForm.find(":input[name='page-slug']").val();
            // Hide any previous errors
            showMarketError("");
            let isValidZip = zipCodeValue && zipPattern.test(zipCodeValue);
            if (isValidZip) {
                // Update the visitor location based on zip code
                const closeCountyModal = $('#countyModal').find('.close');
                closeCountyModal.removeAttr('data-bs-dismiss');
                closeCountyModal.addClass('open-zip-modal');
                getMarketFormAjax($getMarketByZipForm, zipCodeValue, pageSlugValue, 'no-provider', 'no-county');
            } else {
                if (!isValidZip) {
                    // Show invalid zip code format error
                    showMarketError("wrong-zip-format");
                }
            }
        });
    }

    const $getProviderByMarketForm = $(".getProviderByMarketForm");
    if ($getProviderByMarketForm.length) {
        $getProviderByMarketForm.on('submit', function (event) {
            event.preventDefault();
            const provider = $getProviderByMarketForm.find(":input[name='market-provider']").val();
            const county = $getProviderByMarketForm.closest('#providersModal').attr('data-county');
            $getMarketForm = marketModal.length ? $(".getMarketByZipForm") : $(".getMarketForm");
            const zipCodeValue = $getMarketForm.find(":input[name='network-zip-code']").val();
            const pageSlugValue = $getMarketForm.find(":input[name='page-slug']").val();
            getMarketFormAjax($getMarketForm, zipCodeValue, pageSlugValue, provider, county);
        });
    }

    $(document).on('click', '.open-zip-modal', function () {
        $('#countyModal').modal('hide');
        $('#marketModal').modal('show');
    });

});
